import React from "react"
import tw from "twin.macro"
import styled from "styled-components"

import Main from "../../components/layouts/Main"
import Header from "../../components/layouts/Header"
import Row from "../../components/layouts/Row"
import Footer from "../../components/layouts/Footer"
import ReactPlayer from "react-player"

const Title = styled.h1`
    ${tw`text-3xl md:text-5xl font-primary font-black dark:text-white text-gray-700 tracking-tight`}
    span {
        background: var(--pop-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

const Section = styled.section`
    ${tw`py-12 min-h-screen relative z-10 rounded-b-2rem bg-white dark:bg-black`}
    border-radius: 0 0 1rem 1rem;
`

const Terms = (props) => {
    return (
        <Main>
            <Header />
            <Section>
                <Row css={tw`mt-40`}>
                    <div css={tw`md:w-2/3`}>
                        <Title>Hi Y Combinator!</Title>
                        {/* <p>
                            We're wrapping up a demo better than this. Will be
                            done in a few more hours. We didn't expect you read
                            our application so soon :)
                        </p> */}
                    </div>
                </Row>

                <Row css={tw`mt-8`}>
                    {/* <ReactPlayer url="https://youtu.be/6itDNfdM-y0" /> */}
                    <ReactPlayer url="https://youtu.be/rO4S2hrUPbo" />
                </Row>
            </Section>
            <Footer />
        </Main>
    )
}

export default Terms
